import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    createConsent, toggleCreateDialog, applyConsentID, applyConsentHash
} from './blockchainSlice'
import { Modal, ComposedModal, ModalHeader, ModalBody, ModalFooter, Form, TextInput, Select, SelectItem } from 'carbon-components-react'
import { sha3_512 } from 'js-sha3'

export function BlockchainCreateDialog(props) {
    const dispatch = useDispatch();
    const consentID = useSelector((state) => state.blockchain.consentID);
    const consentHash = useSelector((state) => state.blockchain.consentHash);

    return (<div>
        <ComposedModal size="lg" open={props.open} onClose={() => dispatch(toggleCreateDialog())} size="sm" preventCloseOnClickOutside={true} >
            <ModalHeader label=""><h4>Ajouter un consentement à la blockchain</h4>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <div style={{ marginBottom: '2rem' }}>
                        <TextInput
                            helperText=""
                            id="consentID"
                            labelText="ID du consentement"
                            placeholder=""
                            value={consentID}
                            onChange={(event) => {
                                dispatch(applyConsentID(event.target.value));
                                dispatch(applyConsentHash(sha3_512(event.target.value)));
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: '2rem' }}>
                        <TextInput
                            helperText=""
                            id="consentHash"
                            value={consentHash}
                            labelText="Hash du consentement"
                            placeholder=""
                            disabled
                        />
                    </div>
                    <div style={{ marginBottom: '2rem' }}>
                        <Select
                            defaultValue="SHA3-512"
                            id="consentFcn"
                            labelText="Fonction de hash"
                        >
                            <SelectItem
                                text="SHA3-512"
                                value="SHA3-512"
                            />
                        </Select>
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter primaryButtonText="Créer" onRequestSubmit={() => {
                dispatch(createConsent({
                    consentID: consentID,
                    hash: consentHash,
                    hashFunction: "SHA3-512"
                }))
            }} />
        </ComposedModal>
    </div>);
}


import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import '../../common.css';
import {
  Header,
  HeaderMenuButton,
  HeaderName,
  SkipToContent,
  SideNav,
  SideNavItems,
  SideNavLink,
  HeaderGlobalBar,
  HeaderGlobalAction,
  Switcher,
  SwitcherItem,
  HeaderPanel,
  SwitcherDivider
} from "carbon-components-react/lib/components/UIShell";
import HeaderContainer from "carbon-components-react/lib/components/UIShell/HeaderContainer";
import { UserAvatarFilled32 } from '@carbon/icons-react';
import {
  toggleAccountMenu
} from './topnavbarSlice';
import { logout } from '../../appSlice'
import config from '../../config'
import partnerLogo from '../../icons/partner.png'
import { redirectAuthenticated } from '../../api/redirect'
import './topnavbar.css';

export function Topnavbar(props) {
  const dispatch = useDispatch();
  const accountMenuSelected = useSelector((state) => state.topnavbar.accountMenuSelected);

  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label="ByConsent">
          <SkipToContent />
          <HeaderMenuButton
            aria-label="Open menu"
            isCollapsible
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <HeaderName href="#" prefix="">
            {config.loginType === "bytel" ? "ByConsent" : <span className="partnerTitleContainer"><img style={{ width: 45 }} src={partnerLogo} /><span style={{ marginLeft: 10 }}>Fournisseur d'énergie</span></span>}
          </HeaderName>
          <HeaderGlobalBar>
            <HeaderGlobalAction isActive={accountMenuSelected} aria-label="Search" onClick={() => dispatch(toggleAccountMenu())}>
              <UserAvatarFilled32 />
            </HeaderGlobalAction>
          </HeaderGlobalBar>
          <HeaderPanel aria-label="Account Header" expanded={accountMenuSelected}>
            <Switcher aria-label="Switcher Container">
              <SwitcherItem aria-label="api-docs" onClick={() => dispatch(redirectAuthenticated("/api-docs"))}>
                API docs
              </SwitcherItem>
              <SwitcherItem aria-label="blockchain" onClick={() => document.location.href = "/blockchain"}>
                Démo Blockchain
              </SwitcherItem>
              <SwitcherItem aria-label="api-docs" onClick={() => document.location.href = config.demoSwitcherUrl}>
                Démo menu
              </SwitcherItem>
              <SwitcherItem aria-label="logout" onClick={() => dispatch(logout())}>
                Déconnexion
              </SwitcherItem>
            </Switcher>
          </HeaderPanel>
          <SideNav
            aria-label="Side navigation"
            isRail
            className={isSideNavExpanded ? "visible-sidenav" : "hidden-sidenav"}
            expanded={isSideNavExpanded}>
            <SideNavItems>
              <SideNavLink renderIcon={UserAvatarFilled32} onClick={() => dispatch(logout())}>
                Déconnexion
              </SideNavLink>
            </SideNavItems>
          </SideNav>
        </Header>
      )}
    />
  );
}
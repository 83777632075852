import { createSlice } from '@reduxjs/toolkit';

export const topnavbarSlice = createSlice({
  name: 'topnavbar',
  initialState: {
    accountMenuSelected: false,
  },
  reducers: {
    toggleAccountMenu: (state, action) => {
      state.accountMenuSelected = !state.accountMenuSelected;
    },
  },
});
export const {
  toggleAccountMenu
} = topnavbarSlice.actions;

export default topnavbarSlice.reducer;

import { createSlice } from '@reduxjs/toolkit'
import { getConfig } from './api/config'
import {logIn} from "@bytel/react-oauth2";

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        configReceived: false,
        config: { channels: [] }
    },
    reducers: {
        setConfigReceived: (state, action) => {
            state.configReceived = action.payload;
        },
        setConfig: (state, action) => {
            state.config = action.payload;
        },
    },
});
export const { setConfigReceived, setConfig } = appSlice.actions;

export const fetchConfig = () => async dispatch => {
    try {
        const { data } = await getConfig()
        dispatch(setConfigReceived(true));
        dispatch(setConfig(data));
    } catch (error) {
        console.error(error);
    }
};

export const logout = () => dispatch => {
    try {
        logIn();
    } catch (error) {
        console.error(error);
    }
}

export default appSlice.reducer;

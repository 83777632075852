import React from 'react'
import { useSelector } from 'react-redux'
import '../../common.css'
import './blockchain.css'
import { BlockchainSearch } from './BlockchainSearch'
import { BlockchainCreateDialog } from './blockchainCreateDialog'
import { CodeHighlight } from './CodeHighlight';

export function BlockchainView(props) {
    const blockResult = useSelector((state) => state.blockchain.blockResult);
    const showCreateDialog = useSelector((state) => state.blockchain.showCreateDialog);

    return (<div>
        <BlockchainSearch style={{ marginTop: 30 }} searchInputMd={7} buttonMd={1} match={props.match} />
        <div className="blockhainResultContainer">
            {blockResult.error && <div className="blockchainMessage">{blockResult.error}</div>}
            {blockResult.message && <CodeHighlight language="javascript">
                {JSON.stringify(blockResult.message, null, 4)}
            </CodeHighlight>}
        </div>
        <BlockchainCreateDialog open={showCreateDialog} />
    </div>);
}
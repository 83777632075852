import config from '../config'
import axios from 'axios'
import {buildAuthHeaders, logIn} from "@bytel/react-oauth2";

export const getConsents = (options) => {
    return axios.get(`${config.apiUrl}/consents`, {
        params: {
            query: options.query,
            page: options.pageEvent.page,
            pageSize: options.pageEvent.pageSize,
            dateStart: options.dateRange ? (options.dateRange.length > 0 ? options.dateRange[0].getTime() : "") : "",
            dateEnd: options.dateRange ? (options.dateRange.length > 1 ? options.dateRange[1].getTime() : "") : "",
            channel: options.channel,
            showExpired: options.showExpired || false
        },
        headers: buildAuthHeaders()
    }).catch((error) => {
        if (error.response.status === 401) {
            console.warn('login required for api/consent/getConsents(). Authorization header was ' + error.config.headers.Authorization);
            logIn();
        } else {
            throw error;
        }
    });
}
import React  from 'react'
import './App.scss';

import { Root } from './Root'

function App() {
  return (
    <Root />
  );
}

export default App;

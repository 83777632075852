const demoSwitcherUrlMap = {
  "localhost:3002": "http://localhost:3003",
  "localhost:3004": "http://localhost:3003",
  "byconsent-bytel.labinno.fr": "https://byconsent.labinno.fr",
  "byconsent-partner.labinno.fr": "https://byconsent.labinno.fr",
  "byconsent-bytel.apps.ocp-3.ocp.euw3r53.nbyt.fr": "https://byconsent.apps.ocp-3.ocp.euw3r53.nbyt.fr",
  "byconsent-partner.apps.ocp-3.ocp.euw3r53.nbyt.fr": "https://byconsent.apps.ocp-3.ocp.euw3r53.nbyt.fr"
};

export default
  {
    appName: "ByConsent",
    baseUrl: "",
    apiUrl: `${process.env.REACT_APP_SERVER_HOST || ""}/api`,
    loginType: ["byconsent-partner.labinno.fr", "localhost:3004", "byconsent-partner.apps.ocp-3.ocp.euw3r53.nbyt.fr"].includes(window.location.host) ? "partner" : "bytel",
    demoSwitcherUrl: demoSwitcherUrlMap[window.location.host],
    consentHeaders: {
      bytel: [{
        key: 'consentSource',
        header: "Canal d'acquisition",
      }, {
        key: 'imei',
        header: "ID de l'appareil",
      }, {
        key: 'iabVersion',
        header: "Version d'IAB",
      }, {
        key: 'iabCollectTimestampFormatted',
        header: "Date de consentement"
      }, {
        key: 'expirationDateFormatted',
        header: "Date d'expiration"
      }, {
        key: 'authorizedConsentFunctionalitiesFormatted',
        header: "Finalité(s) consentie(s)"
      }, {
        key: 'unallowedConsentFunctionalitiesFormatted',
        header: "Finalité(s) non consentie(s)"
      }],
      partner: [{
        key: 'consentSource',
        header: "Canal d'acquisition",
      }, {
        key: 'iabVersion',
        header: "Version d'IAB",
      }, {
        key: 'iabCollectTimestampFormatted',
        header: "Date de consentement"
      }, {
        key: 'expirationDateFormatted',
        header: "Date d'expiration"
      }, {
        key: 'authorizedConsentFunctionalitiesFormatted',
        header: "Finalité(s) consentie(s)"
      }, {
        key: 'unallowedConsentFunctionalitiesFormatted',
        header: "Finalité(s) non consentie(s)"
      }]
    },
    cookieName: "BYCONSENTID",
    defaultConsentPerPage: 8,
    search: {
      title: "Rechercher votre PU",
      placeHolder: "Id PU",
      helperText: 'Format attendu : "8001234567" qui correspond à votre Personne Unique ID (PU)',
      buttonText: "Rechercher",
      searchRegex: /^[0-9]{0,10}$/,
      backwardText: "Page précédente",
      forwardText: "Page suivante",
      itemsPerPageText: "Quantité par page:",
      pageNumberText: "Nombre de page",
      pageSizeList: [5, 8, 10, 20, 30, 40, 50]
    },
    accountLoader: "En attente du serveur d'authentification",
    defaultChannelDropDown: "Canal d'acquisition",
    clientId: 'byconsent.bouyguestelecom.fr',
    authorizationEndpoint: process.env.REACT_APP_AUTHORIZE_URL || 'https://oauth2.bouyguestelecom.fr/authorize',
    redirectUri: process.env.REACT_APP_REDIRECT_URI || encodeURIComponent(window.location.href) 
  }

export function getLocalStorageItem(name, defaultValue,type) {
    var value = localStorage.getItem(name);
    if (value === null) {
        localStorage.setItem(name, defaultValue);
        return defaultValue;
    }
    if (type === "number"){
        return parseInt(value, 10);
    } else if (type === "boolean"){
        return value === "true";
    }
    return value;
}

export function setLocalStorageItem(name, value){
  localStorage.setItem(name, value);
}
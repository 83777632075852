import { configureStore } from '@reduxjs/toolkit'
import searchReducer from '../components/search/searchSlice'
import topnavbarReducer from '../components/topnavbar/topnavbarSlice'
import appReducer from '../appSlice'
import dashboardReducer from '../components/dashboard/dashboardSlice'
import blockchainReducer from '../components/blockchain/blockchainSlice'

export default configureStore({
  reducer: {
    search: searchReducer,
    topnavbar: topnavbarReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    blockchain: blockchainReducer,
  },
});

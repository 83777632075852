import React from 'react'
import { useSelector } from 'react-redux'
import '../../common.css';
import './stats.css'

import { ChannelDonut } from './ChannelDonut'
import { PurposeUserChart } from './PurposeUserChart'
import { PurposeDashboardChart } from './PurposeDashboardChart'
import { ChannelGauge } from './ChannelGauge'
import config from '../../config'

function getDonutClass(index, totalLen) {
    if (index == 0) {
        return "channel-donut-left"
    } else if (index == totalLen - 1) {
        return "channel-donut-right"
    }
    return "channel-donut"
}

export function Stats(props) {
    const globalStats = useSelector((state) => state.dashboard.globalStats);
    const userStats = useSelector((state) => state.dashboard.userStats);
    return (<div>
        <div style={{ marginTop: 20, display: "flex" }}>{
            props.statsType === "dashboard" && globalStats.donuts && globalStats.donuts.map((it, index) => {
                return (<ChannelDonut
                    key={it.channel}
                    className={getDonutClass(index, globalStats.donuts.length)}
                    style={config.loginType === "partner" ? { marginRight: 40 } : {}}
                    channel={it.channel}
                    data={it.data}
                    userCountText={globalStats.userCountText}
                    userCount={globalStats.userCount}
                    total={it.total} />)
            })
        }{
                props.statsType === "user" && userStats.donuts && userStats.donuts.map((it, index) => {
                    return (<ChannelGauge
                        key={it.channel}
                        className={getDonutClass(index, userStats.donuts.length)}
                        style={config.loginType === "partner" ? { marginRight: 40 } : {}}
                        channel={it.channel}
                        data={it.data}
                        total={it.total}
                    />)
                })
            }
        </div>
        <div style={{ marginTop: 30, marginLeft: 40, marginRight: 40, marginBottom: 30, background: "white" }}>
            {props.statsType === "dashboard" && <PurposeDashboardChart
                title="Répartition par finalité consentie"
                data={globalStats.bar}
                titleX={"Finalités consenties"}
                titleY={config.loginType !== "bytel" ? "% de ID" : "% de PU"}
            />
            }
            {props.statsType === "user" && <PurposeUserChart
                title="Etat courant par finalité consentie"
                data={userStats.bar}
                titleX={"Canaux d'acquisition"}
                titleY={"Finalités consenti ou non"}
            />
            }
        </div >
    </div>);
}
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import '../../common.css';
import './search.css';
import {
    DataTable,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell
} from 'carbon-components-react';

import { Grid, Row, Column, Tag, Pagination } from 'carbon-components-react';
import config from '../../config';
import { SearchFilter } from './SearchFilter'
import { SearchTitle } from './SearchTitle'

import { search } from './searchSlice';

export function SearchDatatable(props) {
    const dispatch = useDispatch();
    const query = useSelector((state) => state.search.query);
    const consentResult = useSelector((state) => state.search.consentResult);
    const consentHeaders = useSelector((state) => state.search.consentHeaders);
    const page = useSelector((state) => state.search.page);
    const pageSize = useSelector((state) => state.search.pageSize);

    return (
        <div className="searchResultGridContainer">

            <Grid>
                <Row>
                    <Column>
                        <div className="searchContainer">
                            <Grid>
                                <Row className="datatable-search-title-container">
                                    <SearchTitle match={props.match} titleType="datatable" />
                                </Row>
                                <Row className="search-datatable-row">
                                    <DataTable rows={consentResult.consents} headers={consentHeaders[config.loginType]}>
                                        {({
                                            rows,
                                            headers,
                                            getHeaderProps,
                                            getRowProps,
                                            getTableProps,
                                            getTableContainerProps,
                                        }) => (
                                                <TableContainer
                                                    title=""
                                                    className="fullWidth"
                                                    description=""
                                                    data-testid="search-result-table"
                                                    {...getTableContainerProps()}>
                                                    <Table {...getTableProps()} isSortable>
                                                        <TableHead>
                                                            <TableRow>
                                                                {headers.map((header) => (
                                                                    <TableHeader
                                                                        key={header.key}
                                                                        {...getHeaderProps({ header })}
                                                                        isSortable>
                                                                        {header.header}
                                                                    </TableHeader>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rows.map((row) => (
                                                                <TableRow key={row.id} {...getRowProps({ row })}>
                                                                    {row.cells.map((cell) => (
                                                                        <TableCell key={cell.id}>{cell.value}</TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                    </DataTable>
                                    <div style={{ width: '100%' }}>
                                        <Pagination pagesUnknown
                                            backwardText={config.search.backwardText}
                                            forwardText={config.search.forwardText}
                                            itemsPerPageText={config.search.itemsPerPageText}
                                            data-testid="search-result-pagination"
                                            page={page}
                                            onChange={(event) => {
                                                dispatch(search({ query: query, pageEvent: event }));
                                            }}
                                            pageNumberText={config.search.pageNumberText}
                                            pageSize={pageSize}
                                            pageSizes={config.search.pageSizeList}
                                        />
                                    </div>
                                </Row>
                            </Grid>
                        </div>
                    </Column>
                </Row>
            </Grid>
        </div>
    );
}
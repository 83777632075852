import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import '../../common.css';
import './stats.css'
import { DonutChart } from "@carbon/charts-react";

import "@carbon/charts/styles.css";
const colors = {
    "oui": "#EB3382",
    "non": "#5923A6"
};
export function ChannelGauge(props) {
    const yesFiltered = props.data.filter(it => it.group === "oui");
    var yesTotal = 0;
    if (yesFiltered.length > 0) {
        yesTotal = props.data.filter(it => it.group === "oui")[0].value;
    }

    return (<div className={props.className} style={props.style}>
        <div style={{ background: "white", display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginLeft: 20, marginTop: 20, fontSize: "1.3rem", fontWeight: "bold" }}>{props.channel}</div>
            <div style={{ marginRight: 20, marginTop: 20, textAlign: "right" }}></div>
        </div>
        <DonutChart
            data={props.data}
            options={{
                "title": "",
                "legend": {
                    "alignment": "center"
                },
                "donut": {
                    "center": {
                        "label": "de finalités acceptées",
                        "number": (yesTotal * 100 / props.total),
                        "numberFormatter": (n) => Math.round(n * 100) / 100 + "%"
                    },
                    "alignment": "center"
                },
                color: {
                    scale: colors
                },
                getFillColor: (field) => {
                    return colors[field];
                },
                "height": "300px"
            }
            }>
        </DonutChart>
    </div >);
}
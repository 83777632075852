import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    DatePicker,
    DatePickerInput,
    Dropdown,
    Button,
    Toggle,
} from 'carbon-components-react'

import { Download32 } from '@carbon/icons-react'
import './search.css'
import { applyChannelFilter, applyGlobalDateRange, applyUserDateRange, applyShowExpired } from '../dashboard/dashboardSlice'
import { search } from './searchSlice'
import config from '../../config'

export function SearchFilter(props) {
    const dispatch = useDispatch();
    const query = useSelector((state) => state.search.query);
    const channelFilter = useSelector((state) => state.dashboard.channelFilter);
    const showExpired = useSelector((state) => state.dashboard.showExpired);
    const globalDateRange = useSelector((state) => state.dashboard.globalDateRange);
    const userDateRange = useSelector((state) => state.dashboard.userDateRange);
    const pageSize = useSelector((state) => state.search.pageSize);
    const configChannels = useSelector((state) => state.app.config);

    return (
        <div className="filterContainer" style={{ right: props.download ? 35 : 78, marginRight: props.rightMargin || 62 }}>
            {config.loginType === "bytel" && props.historyToggle && <Toggle
                defaultToggled
                labelText="historique"
                id="filter-history"
                className="filter-history"
                toggled={showExpired}
                onToggle={(value) => {
                    dispatch(applyShowExpired({
                        query: query,
                        channel: channelFilter,
                        showExpired: value,
                        startDate: userDateRange.length > 0 ? userDateRange[0] : "",
                        endDate: userDateRange.length > 1 ? userDateRange[1] : "",
                        pageEvent: { page: 1, pageSize: pageSize },
                    }))
                }}
                labelA=""
                labelB=""
            />
            }
            {config.loginType === "bytel" && props.channels && <Dropdown
                label={config.defaultChannelDropDown}
                id="filter-dropdown"
                items={
                    [config.defaultChannelDropDown, ...configChannels.channels].map((it, index) => {
                        return { "id": index, "text": it }
                    })
                }
                itemToString={(item) => (item ? item.text : '')}
                value={channelFilter}
                onChange={(value) => {
                    dispatch(applyChannelFilter({
                        query: query,
                        channel: value.selectedItem.id != 0 ? value.selectedItem.text : "",
                        startDate: userDateRange.length > 0 ? userDateRange[0] : "",
                        endDate: userDateRange.length > 1 ? userDateRange[1] : "",
                        pageEvent: { page: 1, pageSize: pageSize },
                    }))
                }
                }
            />
            }
            {config.loginType === "bytel" && <DatePicker locale="fr" datePickerType="range" dateFormat="d/m/yy" className="dateFilterContainer" onChange={(dateRange) => {
                if (props.filterType === "dashboard") {
                    dispatch(applyGlobalDateRange({
                        startDate: dateRange.length > 0 ? dateRange[0].getTime() : "",
                        endDate: dateRange.length > 1 ? dateRange[1].getTime() : ""
                    }))
                } else {
                    dispatch(applyUserDateRange({
                        query: query,
                        startDate: dateRange.length > 0 ? dateRange[0].getTime() : "",
                        endDate: dateRange.length > 1 ? dateRange[1].getTime() : ""
                    }))
                    if (dateRange.length > 1) {
                        dispatch(search({
                            query: query,
                            pageEvent: { page: 1, pageSize: pageSize },
                            dateRange: dateRange
                        }));
                    }
                }
            }} value={props.filterType == "dashboard" ? globalDateRange : userDateRange}>
                <DatePickerInput
                    id="date-picker-input-id-start"
                    placeholder="date début"
                    autoComplete="off"
                    labelText=""
                />
                <DatePickerInput
                    id="date-picker-input-id-finish"
                    placeholder="date fin"
                    autoComplete="off"
                    labelText="&#8594;"
                />
            </DatePicker>
            }
            {props.download && <Button renderIcon={Download32} className="downloadBtn" onClick={() => { }}>Télécharger</Button>}
        </div>
    );
}
import React from 'react'
import {
    Link
} from "react-router-dom"
import './navigator.css'

export function Navigator(props) {
    return (
        <div className="navigator-container">
            <div className="navigator-item">
                {
                    props.nav.map(it => {
                        return (<span key={it.text}><Link className="navigator-link" to={it.url}>{it.text}</Link> / </span>)
                    })
                }
            </div>
        </div>
    );
}



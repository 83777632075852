export function getTitle(type, query) {
    switch (type) {
        case "datatable": {
            return `Historique PU : ${query}`
        }
        case "dashboard": {
            return `Etat général des consentements`
        }
        case "user": {
            return `Etat des consentements de «${query}»`
        }
    }
    return "";
}

export function getContractNumber(consents) {
    if (consents.length > 0) {
        var latest = consents.reduce(function (r, a) {
            return r.iabCollectTimestamp > a.iabCollectTimestamp ? r : a;
        });
        if (latest.associatedFixContractsCount && latest.associatedMobileContractsCount) {
            return latest.associatedFixContractsCount + latest.associatedMobileContractsCount
        }
    }
    return 0;
}
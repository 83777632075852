import React from 'react'
import { useSelector } from 'react-redux'
import '../../common.css';
import './stats.css'
import { DonutChart } from "@carbon/charts-react";

import "@carbon/charts/styles.css";
const colors = {
    "oui à tout": "#EB3382",
    "oui à 1+ finalité": "#157DC7",
    "non à tout": "#5923A6"
};
export function ChannelDonut(props) {
    return (<div className={props.className} style={props.style}>
        <div style={{ background: "white", display: "flex", justifyContent: "space-between" }}>
            <div style={{ marginLeft: 20, marginTop: 20, fontSize: "1.3rem", fontWeight: "bold" }}>{props.channel}</div>
            <div style={{ marginRight: 20, marginTop: 20, fontSize: "1.3rem", fontWeight: "bold" }}>{props.userCountText} de PU</div>
        </div>
        <DonutChart
            data={props.data}
            options={{
                "title": "",
                "legend": {
                    "alignment": "center"
                },
                "donut": {
                    "center": {
                        "label": `${Math.round((props.total*100/props.userCount)*100)/100}% de PU`,
                        "numberFormatter": (n) => n.toLocaleString()  + " PU"
                    },
                    "alignment": "center"
                },
                color: {
                    scale: colors
                },
                getFillColor: (field) => {
                    return colors[field];
                },
                "height": "300px"
            }
            }>
        </DonutChart>
    </div >);
}
import { createSlice } from '@reduxjs/toolkit'
import config from '../../config'
import { getGlobalStats, getUserStats } from '../../api/stats'
import { nFormatter } from '../../utils/numberFormatter'
import { search } from '../search/searchSlice'

export const initialState = {
    globalStatsReceived: false,
    userStatsReceived: false,
    globalStats: { donuts: [], bar: [], userCount: "0" },
    userStats: { gauges: [], bar: [] },
    globalDateRange: [],
    userDateRange: [],
    channelFilter: "",
    showExpired: false,
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setGlobalStatsReceived: (state, action) => {
            state.globalStatsReceived = action.payload
        },
        setUserStatsReceived: (state, action) => {
            state.userStatsReceived = action.payload
        },
        setGlobalStats: (state, action) => {
            state.globalStats = action.payload
        },
        setUserStats: (state, action) => {
            state.userStats = action.payload
        },
        setChannelFilter: (state, action) => {
            state.channelFilter = action.payload
        },
        setGlobalDateRange: (state, action) => {
            state.globalDateRange = action.payload
        },
        setUserDateRange: (state, action) => {
            state.userDateRange = action.payload
        },
        setShowExpired: (state, action) => {
            state.showExpired = action.payload
        },
    },
});

export const {
    setGlobalStatsReceived, setGlobalStats, setUserStatsReceived, setUserStats, setChannelFilter, setGlobalDateRange, setUserDateRange, setShowExpired
} = dashboardSlice.actions;

function processGlobalStatsResult(dispatch, data) {
    if (data.stats.userCount) {
        data.stats.userCountText = nFormatter(data.stats.userCount, 2);
    }
    dispatch(setGlobalStats(data.stats));
}

export const fetchGlobalStats = (options) => async dispatch => {
    try {
        const { data } = await getGlobalStats(options)
        dispatch(setGlobalStatsReceived(true));
        processGlobalStatsResult(dispatch, data);
    } catch (error) {
        console.log(error);
    }
};
export const fetchUserStats = (options) => async dispatch => {
    try {
        const { data } = await getUserStats(options)
        dispatch(setUserStatsReceived(true));
        dispatch(setUserStats(data.stats));
    } catch (error) {
        console.log(error);
    }
};

export const applyChannelFilter = (options) => async dispatch => {
    dispatch(setChannelFilter(options.channel));
    //dispatch(fetchUserStats(options));
    dispatch(search(options));
};
export const applyShowExpired = (options) => async dispatch => {
    dispatch(setShowExpired(options.showExpired));
    //dispatch(fetchUserStats(options));
    dispatch(search(options));
};

export const applyGlobalDateRange = (options) => async dispatch => {
    var dateRangeState = [];
    if (options.startDate) {
        dateRangeState.push(options.startDate);
    }
    if (options.endDate) {
        dateRangeState.push(options.endDate);
    }
    dispatch(setGlobalDateRange(dateRangeState))
    if (dateRangeState.length > 1) {
        dispatch(fetchGlobalStats(options));
    }
};
export const applyUserDateRange = (options) => async dispatch => {
    var dateRangeState = [];
    if (options.startDate) {
        dateRangeState.push(options.startDate);
    }
    if (options.endDate) {
        dateRangeState.push(options.endDate);
    }
    dispatch(setUserDateRange(dateRangeState))
    if (dateRangeState.length > 1) {
        dispatch(fetchUserStats(options));
    }
};
export default dashboardSlice.reducer;

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    search, applyQuery
} from './searchSlice'
import '../../common.css'
import './search.css'
import { Button, Search as SearchInput, Row, Column } from 'carbon-components-react'
import config from '../../config'
import { useHistory } from "react-router-dom"
import { fetchUserStats } from '../dashboard/dashboardSlice'

export function SearchForm(props) {
    const dispatch = useDispatch();
    const query = useSelector((state) => state.search.query);
    const pageSize = useSelector((state) => state.search.pageSize);
    const history = useHistory();

    useEffect(() => {
        if (!query && props.match && props.match.params.id) {
            dispatch(applyQuery(props.match.params.id));
        }
    });

    return (
        <div className="search-form-container">
            <div className="search-form-left">
                <div className="searchInput">
                    <SearchInput
                        id="search"
                        value={query}
                        labelText=""
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                dispatch(search({
                                    query: query,
                                    pageEvent: { page: 1, pageSize: pageSize }
                                }, history));
                            }
                        }}
                        onChange={(event) => {
                            if (props.match && props.match.params.id && !event.target.value) {
                                dispatch(applyQuery(event.target.value));
                                history.push("/dashboard");
                                return;
                            }
                            dispatch(applyQuery(event.target.value));
                        }}
                        placeHolderText={config.loginType !== "bytel" ? "" : config.search.placeHolder}
                    />
                </div>
                <div className="searchHelperText">
                    {config.loginType !== "bytel" ? "" : config.search.helperText}
                </div>
            </div>
            <div className="search-form-right">
                <Button className="searchBtn" onClick={() => {
                    dispatch(search({
                        query: query,
                        pageEvent: { page: 1, pageSize: pageSize }
                    }, history));
                    dispatch(fetchUserStats({ query: query }));
                }}>{config.search.buttonText}</Button>
            </div>
        </div>
    );
}
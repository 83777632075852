import React from 'react'
import { useSelector } from 'react-redux'
import '../../common.css';
import './stats.css'
import { GroupedBarChart } from "@carbon/charts-react";
import "@carbon/charts/styles.css";

const ticks = {
    "1": "consenti",
    "0": "",
    "-1": "non consenti"
}
const leftAxis = {
    "mapsTo": "value",
    "title": "",
    "ticks": {
        values: [-1, 0, 1],
        formatter: (n) => {
            return ticks[n];
        }
    },
    domain: [-1, 1]
}

export function PurposeUserChart(props) {
    return (<div className={props.className} style={{ paddingBottom: 30, paddingRight: 30, paddingLeft: 30 }}>
        <div style={{ background: "white", marginLeft: 20, paddingTop: 20, fontSize: "1.3rem", fontWeight: "bold", marginBottom: 20 }}>{props.title}</div >
        <GroupedBarChart
            data={props.data}
            options={{
                "title": "",
                "axes": {
                    "left": leftAxis,
                    "right": leftAxis,
                    "bottom": {
                        "scaleType": "labels",
                        "mapsTo": "key",
                        "title": props.titleX
                    }
                },
                "height": "400px",
                zoomBar: false
            }}>
        </GroupedBarChart>
    </div >);
}
import { createSlice } from '@reduxjs/toolkit'
import { getConsent, setConsent } from '../../api/blockchain'

export const initialState = {
    query: "",
    blockResult: {},
    showCreateDialog: false,
    consentID: "",
    consentHash: ""
}

export const blockchainSlice = createSlice({
    name: 'blockchain',
    initialState,
    reducers: {
        applyQuery: (state, action) => {
            state.query = action.payload;
            if (!state.query && (state.blockResult.message || state.blockResult.error)) {
                state.blockResult = {};
            }
        },
        setBlockResult: (state, action) => {
            state.blockResult = action.payload;
        },
        toggleCreateDialog: (state, action) => {
            state.showCreateDialog = !state.showCreateDialog;
        },
        applyConsentID: (state, action) => {
            state.consentID = action.payload;
        },
        applyConsentHash: (state, action) => {
            state.consentHash = action.payload;
        },
    },
});

export const {
    applyQuery, setBlockResult, toggleCreateDialog, applyConsentHash, applyConsentID
} = blockchainSlice.actions;

export const searchConsent = (options) => async dispatch => {
    try {
        const { data } = await getConsent(options)
        dispatch(setBlockResult(data));
    } catch (error) {
        console.log(error);
    }
};

export const createConsent = (options) => async dispatch => {
    try {
        const { data } = await setConsent(options)
        dispatch(setBlockResult(data));
    } catch (error) {
        console.log(error);
    }
    dispatch(toggleCreateDialog());
};

export default blockchainSlice.reducer;

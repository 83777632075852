import config from "../config";
import axios from "axios";
import { buildAuthHeaders, logIn } from "@bytel/react-oauth2";

export const getConsent = (options) => {
  return axios
    .get(`${config.apiUrl}/blockchain/query/${options.query || ""}`, {
      headers: buildAuthHeaders(),
    })
    .catch((error) => {
      if (error.response.status === 401) {
        console.warn(
          "login required for api/blockchain/getConsent(). Authorization header was " +
            error.config.headers.Authorization
        );
        logIn();
      } else {
        throw error;
      }
    });
};

export const setConsent = (data) => {
  return axios({
    method: "POST",
    url: `${config.apiUrl}/blockchain/invoke`,
    headers: buildAuthHeaders(),
    data: data,
  }).catch((error) => {
    if (error.response.status === 401) {
      console.warn(
        "login required for api/blockchain/setConsent(). Authorization header was " +
          error.config.headers.Authorization
      );
      logIn();
    } else {
      throw error;
    }
  });
};

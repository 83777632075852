import React from 'react'
import { useSelector } from 'react-redux'
import '../../common.css'
import './search.css'
import { Row, Column, Tag } from 'carbon-components-react'
import { SearchFilter } from './SearchFilter'
import { getTitle, getContractNumber } from '../../utils/searchUtils'
import config from '../../config'

export function SearchTitle(props) {
    const query = useSelector((state) => state.search.query);
    const consentResult = useSelector((state) => state.search.consentResult);
    const contractNumber = getContractNumber(consentResult.consents);

    return (<Row className={props.className}>
        <Column className={props.titleType === "datatable" ? "columnTitle-datatable" : "columnTitle-dashboard"}>
            <span data-testid={`searchTitle-${props.titleType}`} style={{ fontSize: props.fontSize || "1.4rem", whiteSpace: "nowrap", marginTop: props.titleType === "datatable" ? 14 : 10 }}>{getTitle(props.titleType, props.match ? props.match.params.id : query)}</span>
            {config.loginType === "bytel" && props.titleType !== "dashboard" && <Tag className="contractTag">{contractNumber} contrat{contractNumber > 0 ? "s" : ""}</Tag>}
            <SearchFilter loginType={config.loginType} rightMargin={props.titleType === "datatable" ? 10 : 62} historyToggle={props.titleType === "datatable"} download={props.titleType === "datatable"} channels={props.titleType === "datatable"} filterType={props.titleType} />
        </Column>
    </Row>);
}
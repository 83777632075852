import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    searchConsent, applyQuery, toggleCreateDialog
} from './blockchainSlice'
import '../../common.css'
import '../search/search.css'
import { Button, Search as SearchInput, Row, Column } from 'carbon-components-react'
import config from '../../config'
import { useHistory } from "react-router-dom"
import { fetchUserStats } from '../dashboard/dashboardSlice'

export function BlockchainSearch(props) {
    const dispatch = useDispatch();
    const query = useSelector((state) => state.blockchain.query);

    return (
        <div className="blockchain-form-container">
            <div className="blockchain-form-left">
                <div className="searchInput">
                    <SearchInput
                        id="blockchainSearch"
                        value={query}
                        labelText=""
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                dispatch(searchConsent({
                                    query: query,
                                }));
                            }
                        }}
                        onChange={(event) => {
                            dispatch(applyQuery(event.target.value));
                        }}
                        placeHolderText=""
                    />
                </div>
                <div className="blockchainHelperText">
                    ID du consentement
                </div>
            </div>
            <div className="blockchain-form-search-right">
                <Button className="searchBlockchainBtn" onClick={() => {
                    dispatch(searchConsent({
                        query: query,
                    }));
                }}>{config.search.buttonText}</Button>
            </div>
            <div className="blockchain-form-create-right">
                <Button kind='secondary' className="searchBlockchainBtn" onClick={() => {
                    dispatch(toggleCreateDialog());
                }}>Créer un consentement sur la blockchain</Button>
            </div>
        </div>
    );
}
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { search } from '../search/searchSlice'
import '../../common.css';
import { SearchForm } from '../search/SearchForm'
import './dashboard.css'
import { SearchDatatable } from '../search/SearchDatatable'
import { SearchTitle } from '../search/SearchTitle'
import { Stats } from '../stats/Stats'
import { fetchGlobalStats, fetchUserStats } from './dashboardSlice'

export function Dashboard(props) {
    const dispatch = useDispatch();
    const consentReceived = useSelector((state) => state.search.consentReceived);
    const globalStatsReceived = useSelector((state) => state.dashboard.globalStatsReceived);
    const userStatsReceived = useSelector((state) => state.dashboard.userStatsReceived);
    const pageSize = useSelector((state) => state.search.pageSize);

    useEffect(() => {
        if (props.match.params.id) {
            if (!consentReceived) {
                dispatch(search({
                    query: props.match.params.id,
                    pageEvent: { page: 1, pageSize: pageSize }
                }));
            }
            if (!userStatsReceived) {
                dispatch(fetchUserStats({ query: props.match.params.id }));
            }
        } else if (!globalStatsReceived) {
            dispatch(fetchGlobalStats({}));
        }
    });
    const showUser = props.match.params.id && consentReceived;
    return (
        <div id="dashboard-container">
            <SearchForm style={{ marginTop: 30 }} searchInputMd={7} buttonMd={1} match={props.match} />
            <SearchTitle match={props.match} titleType={showUser ? "user" : "dashboard"} className="dashboard-search-title" fontSize="1.8rem" />
            {!props.match.params.id && <Stats statsType="dashboard" />}
            {showUser && <Stats statsType="user" />}
            {showUser && <SearchDatatable match={props.match} />}
        </div>
    );
}
import { createSlice } from '@reduxjs/toolkit'
import config from '../../config'
import { getConsents } from '../../api/consent'
import moment from 'moment'

export const initialState = {
  query: "",
  consentResult: { consents: [] },
  consentHeaders: config.consentHeaders,
  consentReceived: false,
  page: 1,
  pageSize: config.defaultConsentPerPage
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    applyQuery: (state, action) => {
      if (config.loginType === "bytel") {
        if (config.search.searchRegex.test(action.payload)) {
          state.query = action.payload;
        }
      } else {
        state.query = action.payload;
      }
      if (!state.query && state.consentResult.consents.length > 0) {
        state.consentResult.consents = [];
        state.consentReceived = false;
      }
    },
    setPage: (state, action) => {
      if (state.page !== action.payload.page) {
        state.page = action.payload.page
      }
      if (state.pageSize !== action.payload.pageSize) {
        state.pageSize = action.payload.pageSize
      }
    },
    setConsents: (state, action) => {
      state.consentResult = action.payload
    },
    setConsentReceived: (state, action) => {
      state.consentReceived = action.payload
    },
  },
});

export const {
  applyQuery, setPage, setConsents, setConsentReceived
} = searchSlice.actions;

//export const search = (query, pageEvent, history, dateRange, channel) => async dispatch => {
export const search = (options, history) => async dispatch => {
  dispatch(setPage(options.pageEvent));
  try {
    const { data } = await getConsents(options)
    dispatch(setConsentReceived(true));
    data.consents.map(it => {
      it.iabCollectTimestampFormatted = moment(new Date(it.iabCollectTimestamp)).format("DD/MM/YYYY à HH:mm:ss")
      it.expirationDateFormatted = moment(new Date(it.expirationDate)).format("DD/MM/YYYY à HH:mm:ss")
      it.authorizedConsentFunctionalitiesFormatted = it.authorizedConsentFunctionalities ? it.authorizedConsentFunctionalities.toString() : "";
      it.unallowedConsentFunctionalitiesFormatted = it.unallowedConsentFunctionalities ? it.unallowedConsentFunctionalities.toString() : "";
      return it
    })
    dispatch(setConsents(data));
    if (history) {
      history.push(`/user/${options.query}`);
    }
  } catch (error) {
    console.log(error);
  }
};

export const selectQuery = state => state.search.query;
export const selectConsentResult = state => state.search.consentResult;
export const selectPage = state => state.search.page;
export const selectPageSize = state => state.search.pageSize;

export default searchSlice.reducer;

import React, { useEffect } from 'react';
import config from './config'
import { useSelector, useDispatch } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'
import { AuthProvider, AuthenticatedRoute } from '@bytel/react-oauth2'

import { customHistory } from './utils/history'

import { Topnavbar } from './components/topnavbar/Topnavbar'
import { BlockchainView } from './components/blockchain/BlockchainView'
import { Dashboard } from './components/dashboard/Dashboard'

import { Loader } from './components/loader/Loader'
import { Navigator } from './components/navigator/Navigator'

import { fetchConfig } from './appSlice';

function generateNavigatorItems(options) {
  const conf = [{ text: "Dashboard", url: "/dashboard" }];
  if (options.id) {
    conf.push({
      text: `Fiche PU : ${options.id}`,
      url: `/user/${options.id}`
    })
  } else if (options.blockchain) {
    conf.push({
      text: `Démo blockchain`,
      url: `/blockchain`
    })
  }
  return conf
}

export function Root() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchConfig());
  });

  return (
      <AuthProvider configProvider={() => Promise.resolve(config)}>
        <Router history={customHistory}>
          <Topnavbar />
          {/*{getUserInfos() ? <Switch>*/}
            <AuthenticatedRoute exact={true} path={[`${config.baseUrl}/`, `${config.baseUrl}/dashboard`, `${config.baseUrl}/user/:id`]} render={(routeProps) => (
              <div style={{ position: "absolute", width: "100%", marginTop: 47 }}>
                <Navigator nav={generateNavigatorItems({ id: routeProps.match.params.id })} />
                <Dashboard {...routeProps} />
              </div>
            )} />
            <AuthenticatedRoute path={`${config.baseUrl}/blockchain`} render={(routeProps) => (
              <div style={{ position: "absolute", width: "100%", marginTop: 47 }}>
                <Navigator nav={generateNavigatorItems({ blockchain: true })} />
                <BlockchainView {...routeProps} />
              </div>
            )} />
          {/*</Switch> : <Loader />*/}
          {/*}*/}
        </Router>
      </AuthProvider>
  );
}

import config from '../config'
import axios from 'axios'
import {buildAuthHeaders, logIn} from "@bytel/react-oauth2";

export const getConfig = () => {
    return axios.get(`${config.apiUrl}/config`, {headers: buildAuthHeaders()}).catch((error) => {
        if (error.response.status === 401) {
            console.warn('login required for api/config/getConfig(). Authorization header was ' + error.config.headers.Authorization);
            logIn();
        } else {
            throw error;
        }
    });
}
import config from '../config'
import axios from 'axios'
import {buildAuthHeaders, logIn} from "@bytel/react-oauth2";

export const getGlobalStats = (options) => {
    return axios.get(`${config.apiUrl}/globalstats?startDate=${options.startDate || ""}&endDate=${options.endDate || ""}`, {headers: buildAuthHeaders()}).catch((error) => {
        if (error.response.status === 401) {
            console.warn('login required for api/stats/getGlobalStats(). Authorization header was ' + error.config.headers.Authorization);
            logIn();
        } else {
            throw error;
        }
    });
}

export const getUserStats = (options) => {
    return axios.get(`${config.apiUrl}/stats/${options.query}?startDate=${options.startDate || ""}&endDate=${options.endDate || ""}`, {headers: buildAuthHeaders()}).catch((error) => {
        if (error.response.status === 401) {
            console.warn('login required for api/stats/getUserStats(). Authorization header was ' + error.config.headers.Authorization);
            logIn();
        } else {
            throw error;
        }
    });
}